export function isNumberInteger(val) {
  if (isNaN(Number(val.key))) {
    return val.preventDefault();
  }
}


export function formatScore(val) {
  const score = val ? parseFloat(val).toFixed(2).toString() : '0'

  return score
}
